<template>
  <div class="container">
    <div class="products">
      <div class="col">
        <h3>Insights - planning - tracking</h3>
        <HomeProduct :destination="nav365Url" :enabled="hasNavigator">
          <template #content
            ><div class="product-wrapper">
              <inline-svg
                class="product"
                :src="require('../../assets/home/product-svg/core_byline.svg')"
              /></div
          ></template>
        </HomeProduct>

        <HomeProduct :destination="plannerUrl" :enabled="hasPlanner">
          <template #content
            ><div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/planner_byline.svg')
                "
              /></div
          ></template>
        </HomeProduct>

        <HomeProduct :destination="trackerUrl" :enabled="hasTracker">
          <template #content
            ><div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/tracker_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>

        <HomeProduct :destination="benchmarkUrl" :enabled="hasBenchmark">
          <template #content
            ><div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/benchmark_byline.svg')
                "
              /></div
          ></template>
        </HomeProduct>
      </div>
      <div class="col">
        <h3>Capability building</h3>

        <HomeProduct
          :destination="
            hasGame
              ? getGameLink
              : 'https://www.across.health/offering/scala365-game'
          "
          :enabled="hasGame"
        >
          <template #content>
            <div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/omnitopia_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>

        <HomeProduct
          :destination="missingManualUrl"
          :enabled="hasMissingManual"
        >
          <template #content>
            <div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/missing_manual_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>
      </div>
    </div>
    <div class="products" v-if="hasManagementAccess">
      <div class="col">
        <h3>Atlas management modules</h3>

        <HomeProduct
          :destination="hasReporting ? reportingUrl : '#'"
          :enabled="hasReporting"
        >
          <template #content>
            <div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/reporter_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>

        <HomeProduct
          :destination="
            hasSubscriptionManagement ? subscriptionManagementUrl : '#'
          "
          :enabled="hasSubscriptionManagement"
        >
          <template #content>
            <div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/subscription_management_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>

        <HomeProduct
          :destination="hasPlatformManagement ? platformManagementUrl : '#'"
          v-if="hasPlatformManagement"
          :enabled="hasPlatformManagement"
        >
          <template #content>
            <div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/platform_management_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>
      </div>

      <div class="col">
        <h3 class="heading-invisible">&nbsp;</h3>

        <HomeProduct
          :destination="hasProposalBuilder ? proposalBuilderUrl : '#'"
          :enabled="hasProposalBuilder"
        >
          <template #content>
            <div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/proposal_builder_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>

        <HomeProduct
          :destination="hasDatasetManagement ? datasetManagementUrl : '#'"
          :enabled="hasDatasetManagement"
        >
          <template #content>
            <div class="product-wrapper">
              <inline-svg
                class="product"
                :src="
                  require('../../assets/home/product-svg/dataset_management_byline.svg')
                "
              />
            </div>
          </template>
        </HomeProduct>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import products from '@/utilities/products';
import {
  BENCHMARK_URL,
  MANAGEMENT_TOOL_URL,
  MISSING_MANUAL_URL,
  NAV365_URL,
  PERMISSIONS,
  PLANNER_URL,
  TRACKER_URL,
} from '@/utilities/constants';
import HomeProduct from '@/components/home/HomeProduct';

export default {
  name: 'HomeProducts',
  components: { HomeProduct },
  computed: {
    ...mapState({
      loadingSubscriptions: (state) => state.subscriptions.listLoading,
      subscriptions: (state) => state.subscriptions.listResult?.data ?? [],
      loadingGame: (state) => state.scala.gameLoading,
      game: (state) => state.scala.gameResult,
    }),
    ...mapGetters({
      hasPermission: 'auth/hasPermission',
      hasManagementAccess: 'auth/hasManagementAccess',
    }),
    hasNavigator() {
      return this.hasProduct(products.CORE.value);
    },
    nav365Url() {
      return this.hasNavigator
        ? NAV365_URL
        : 'https://www.across.health/offering/navigator365-core';
    },
    hasPlanner() {
      return this.hasProduct(products.PLANNER.value);
    },
    plannerUrl() {
      return this.hasPlanner
        ? PLANNER_URL
        : 'https://www.across.health/offering/navigator365-planner';
    },
    hasTracker() {
      return this.hasProduct(products.TRACKER.value);
    },
    trackerUrl() {
      return this.hasTracker
        ? TRACKER_URL
        : 'https://www.across.health/offering/navigator365-tracker';
    },
    hasMissingManual() {
      return this.hasProduct(products.MISSING_MANUAL.value);
    },
    missingManualUrl() {
      return this.hasMissingManual
        ? MISSING_MANUAL_URL
        : 'https://www.across.health/missing-manual';
    },
    hasBenchmark() {
      return this.hasProduct(products.BENCHMARK.value);
    },
    benchmarkUrl() {
      return this.hasBenchmark
        ? BENCHMARK_URL
        : 'https://across.health/benchmark';
    },
    hasGame() {
      return this.game?.success ?? false;
    },
    getGameLink() {
      return this.game.data.url;
    },

    hasSubscriptionManagement() {
      return this.hasPermission(PERMISSIONS.SubscriptionManagement);
    },
    subscriptionManagementUrl() {
      const url = new URL(MANAGEMENT_TOOL_URL);
      url.pathname = '/subscription-management';
      return url;
    },
    hasDatasetManagement() {
      return this.hasPermission(PERMISSIONS.DatasetManagement);
    },
    datasetManagementUrl() {
      const url = new URL(MANAGEMENT_TOOL_URL);
      url.pathname = '/dataset-management';
      return url;
    },
    hasReporting() {
      return this.hasPermission(PERMISSIONS.Reporting);
    },
    reportingUrl() {
      const url = new URL(MANAGEMENT_TOOL_URL);
      url.pathname = '/reporter';
      return url;
    },
    hasProposalBuilder() {
      return (
        this.hasPermission(PERMISSIONS.OrderManagement) ||
        this.hasPermission(PERMISSIONS.ProductionManagement) ||
        this.hasPermission(PERMISSIONS.FinancialManagement)
      );
    },
    proposalBuilderUrl() {
      const url = new URL(MANAGEMENT_TOOL_URL);
      url.pathname = '/proposal-builder';
      return url;
    },
    hasPlatformManagement() {
      return this.hasPermission(PERMISSIONS.PlatformManagement);
    },
    platformManagementUrl() {
      const url = new URL(MANAGEMENT_TOOL_URL);
      url.pathname = '/platform-management';
      return url;
    },
  },
  methods: {
    ...mapActions({
      fetchSubscriptionList: 'subscriptions/fetchList',
      fetchGame: 'scala/fetchGame',
    }),
    hasProduct(value) {
      return this.subscriptions.some(
        (s) => s.isActive && s.product.value === value,
      );
    },
  },
  mounted() {
    this.fetchSubscriptionList();
    this.fetchGame();
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.products {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  h3 {
    margin-top: 0;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 125%;
    letter-spacing: 0.075rem;
  }

  .col {
    width: 100%;
    padding: $box-padding;
  }

  @media (max-width: 780px) {
    flex-direction: column;
  }
}

.product {
  .product-wrapper {
    border-radius: $border-radius;
    background-color: $white;
    padding: 0.8rem;
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: left;
    align-items: center;

    @include default-box-shadow;
    @include transition;

    .product {
      height: 45px;
      width: auto;
    }
    &:hover {
      @include hover-box-shadow;
      @include transition;
    }

    .invalid_product {
      display: none;
    }

    &.inactive {
      position: relative;
      opacity: 0.5;

      .invalid_product {
        display: block;
        position: absolute;
        right: calc(-100px - 15px);
        width: 100px;
        background: $orange;
        top: 0px;
        height: 100%;
        border-radius: 0px $border-radius $border-radius 0px;
        color: white;
        padding-left: 15px;
        font-size: 10px;
        line-height: 12px;

        @include transition;

        strong {
          font-size: 11px;
          display: block;
          margin-bottom: 10px;
        }
      }

      &:hover {
        box-shadow: none;
        opacity: 1;

        .invalid_product {
          transform: translateX(-100px - 15px);
          @include transition;
        }
      }
    }
  }
}

.heading-invisible {
  color: transparent;
}

.coming-soon {
  strong {
    font-size: 1rem !important;
    margin-top: 2rem;
  }
}

.scala-game {
  display: block;
  margin-left: 0.5rem;

  img {
    display: block;
    //height: 100%;
    width: 80%;
    object-fit: contain;
  }
}
</style>
