import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './styles/global.scss';

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueTippy, { TippyComponent } from 'vue-tippy';
Vue.use(VueTippy, {
  directive: 'tippy',
});
Vue.component('tippy', TippyComponent);

import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);

import './utilities/filters';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
