<template>
  <div>
    <div class="splashpage">
      <button class="btn-close" @click="$emit('close')">
        skip
        <inline-svg
          class="svg-icon"
          :src="require('@/assets/home/splashpage/carousel/chevron-right.svg')"
          width="0.75rem"
          height="0.75rem"
        />
      </button>
      <div class="firstcol col-1-1">
        <div class="col-2-3">
          <div class="header">
            <h1>Hello {{ firstName }}, welcome to Navigator365&trade;</h1>
            <hr />
          </div>
          <div class="content">
            <div class="tag">HCP insights</div>
            <img
              :src="require('@/assets/logo/nav365Core.svg')"
              alt="Navigator365"
              class="logo"
            />
            <p class="intro">
              A treasure trove with over 50 million datapoints to give you
              insights into the omnichannel customer and competition landscape.
            </p>
            <div class="features">
              <h2>Features</h2>
              <div class="col-1-1">
                <div class="col-1-2">
                  <ul>
                    <li>Covering over 50 communication channels</li>
                    <li>Intuitive reports in PDF and PowerPoint</li>
                    <li>Slice &amp; dice with analytic PowerTools</li>
                  </ul>
                </div>
                <div class="col-1-2">
                  <ul>
                    <li>NEW in 2022: Omnichannel Launch section</li>
                    <li>Free planning &amp; tracking tools included</li>
                    <li>Trusted by 50+ life science companies</li>
                  </ul>
                </div>
              </div>
              <a
                href="https://nav365.across.health/redirect?postLoginRedirect=false"
                class="btn"
                >Access Navigator365&trade; Core now</a
              >
            </div>
            <div class="cards">
              <div class="col-1-1">
                <div class="col-1-2">
                  <div class="tag">planning</div>
                  <img
                    :src="require('@/assets/logo/navPlanner.svg')"
                    alt="Navigator365"
                    class="logo"
                  />
                  <p class="intro">
                    Planner helps you build a robust <br />omnichannel plan.
                  </p>
                  <img
                    :src="require('@/assets/home/splashpage/Planner.png')"
                    alt="Navigator365"
                    class="graph"
                  />
                </div>
                <div class="col-1-2">
                  <div class="tag">tracking</div>
                  <img
                    :src="require('@/assets/logo/navTracker.svg')"
                    alt="Navigator365"
                    class="logo"
                  />
                  <p class="intro">
                    Structured DIY campaign tracking<br />
                    &amp; reporting.
                  </p>
                  <img
                    :src="require('@/assets/home/splashpage/Tracker.png')"
                    alt="Navigator365"
                    class="graph"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="checkbox">
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                v-model="checkbox"
                v-on:change="saveCheckbox()"
              />
              <label for="checkbox">Don't show me this page again</label>
            </div>
          </div>
        </div>
        <div class="col-1-3">
          <Carousel />
        </div>
      </div>
    </div>
    <div class="overlay" @click="$emit('close')"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Carousel from '@/components/home/Carousel';
import { setLocalStorage } from '@/utilities/storage-helper';
export default {
  components: { Carousel },
  name: 'SplashPage',
  props: {
    open: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      name: 'auth/userFullName',
      enterprise: 'auth/enterpriseName',
    }),
    firstName() {
      const firstName = this.name.split(' ').slice(0, 1).join(' ');
      return firstName;
    },
  },
  data() {
    return {
      checkbox: false,
    };
  },
  methods: {
    saveCheckbox() {
      setLocalStorage('splashpage', this.checkbox);
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';

.overlay {
  background: #282c3c;
  display: block;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: initial;
  opacity: 0.35;
  z-index: 1;
}
.splashpage {
  position: absolute;
  background-color: $white;
  width: 90%;
  height: auto;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  max-width: 1280px;
  .btn-close {
    position: absolute;
    right: 0;
    top: -1.5rem;
    background-color: transparent;
    color: white;
    border: 0;
    text-transform: uppercase;
    font-weight: 600;
    .svg-icon {
      fill: white;
    }
    &:hover {
      color: $orange;
      fill: $orange;
      cursor: pointer;
    }
    &:hover .svg-icon {
      fill: $orange;
    }
  }
  .col-1-1 {
    width: 100%;
    display: flex;
    height: 100%;
    column-gap: 2rem;
    hr {
      width: calc(100% + 2rem);
      margin: 1rem -1rem;
      border: 0;
      border-top: 2px solid #f2f4f7;
    }
    &.firstcol {
      column-gap: 0;
    }
    .col-1-3 {
      width: 40%;
      padding: 1rem;
      padding-left: 0;
    }
    .col-1-2 {
      width: 50%;
    }
    .col-2-3 {
      width: 60%;
      padding: 1rem;
      display: block;
      overflow: auto;
      .header {
        h1 {
          width: 100%;
          margin: 0 auto;
          max-width: 600px;
          font-weight: 600;
          padding: 1rem 0 0 0;
          margin-bottom: 1rem;
        }
      }
      .content {
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        .tag {
          text-transform: uppercase;
          background-color: $blue-darker;
          color: white;
          padding: 0 0.5rem;
          display: table;
          margin-top: 2rem;
          font-size: 0.8rem;
        }
        .logo {
          width: 50%;
          margin: 0.75rem 0 -0.75rem -5px;
        }
        .intro {
          font-weight: 600;
          color: $gray-darker-splash;
          font-size: 0.9rem;
          line-height: 1.2rem;
        }
        .btn {
          background-color: $green;
          border: 0;
          padding: 1rem;
          color: white;
          font-size: 1rem;
          border-radius: 5px;
          margin-top: 1rem;
          font-family: inherit;
          font-weight: 600;
          width: 100%;
          display: block;
          text-align: center;
          text-decoration: none;
          box-sizing: border-box;
          &:hover {
            background-color: $green-dark;
            cursor: pointer;
            transition: 0.3s;
          }
        }
        h2 {
          font-weight: 700;
        }
        .features {
          margin-top: 2rem;
          .col-1-2 {
            ul {
              margin-top: 0;
              padding-left: 1.5rem;
              list-style-image: url('@/assets/home/splashpage/check-blue.svg');
              li {
                padding: 0.2rem;
                font-weight: 600;
                font-size: 0.9rem;
              }
              li::marker {
                font-size: 2rem;
              }
            }
          }
        }
        .cards {
          margin-top: 4rem;
          height: 240px;
          overflow: hidden;
          margin-bottom: 2rem;
          .col-1-1 {
            column-gap: 2rem;
          }
          .col-1-2 {
            background-color: $gray-light-splash;
            padding: 1rem;
            border: 1px solid $gray-splash;
            border-radius: 5px;
            .tag {
              margin-top: 0.5rem;
            }
            .logo {
              width: 80%;
            }
            .intro {
              font-size: 0.9rem;
              line-height: 1.2rem;
              color: $gray-darker-splash;
            }
            .graph {
              width: 100%;
              border: 1px solid $gray-splash;
              border-radius: 0.5rem;
            }
          }
        }
      }
      .checkbox {
        label {
          font-size: 0.9rem;
          padding: 0.5rem;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .splashpage .col-1-1 .col-2-3 .content .cards {
    height: 180px;
  }
}
@media (max-width: 800px) {
  .splashpage,
  .overlay {
    display: none;
  }
}
</style>
